import { useEffect, useCallback } from "react";

interface UseMoneyTagAdsReturn {
    showAd: (type?: 'interstitial' | 'popup' | 'inApp', options?: any) => Promise<void>;
}

export const useMoneyTagAds = (): UseMoneyTagAdsReturn => {
    useEffect(() => {
        if ((window as any).show_8940691) {
            return;
        }

        const tag = document.createElement("script");

        tag.src = "https://niphaumeenses.net/vignette.min.js";
        tag.dataset.zone = process.env.ADS_MONEYTAG_ZONE_ID;
        tag.dataset.sdk = process.env.ADS_MONEYTAG_SDK_ID;

        document.body.appendChild(tag);
    }, []);

    const showAd = useCallback((type: 'interstitial' | 'popup' | 'inApp' = 'interstitial', options?: any): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (typeof (window as any).show_8940691 !== "function") {
                reject(new Error("Ad SDK is not available"));
                return;
            }

            switch (type) {
                case 'interstitial':
                    (window as any)
                        .show_8940691()
                        .then(() => {
                            resolve();
                        })
                        .catch((err: any) => {
                            reject(err);
                        });
                    break;

                case 'popup':
                    (window as any)
                        .show_8940691('pop')
                        .then(() => {
                            resolve();
                        })
                        .catch((err: any) => {
                            reject(err);
                        });
                    break;

                case 'inApp':
                    (window as any)
                        .show_8940691({
                            type: 'inApp',
                            inAppSettings: options || {
                                frequency: 2,
                                capping: 0.1,
                                interval: 30,
                                timeout: 5,
                                everyPage: false,
                            },
                        })
                        .then(() => {
                            resolve();
                        })
                        .catch((err: any) => {
                            reject(err);
                        });
                    break;

                default:
                    reject(new Error("Unsupported ad type"));
            }
        });
    }, []);

    return { showAd };
};